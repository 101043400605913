<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="1" class="mr-2">
                <v-text-field
                    label="Column"
                    v-model="solver.column"
                />
            </v-col>

            <v-col cols="2" class="mr-2">
                <v-text-field
                    label="Column name"
                    v-model="solver.columnName"
                />
            </v-col>

            <v-col cols="1" class="mr-2">
                <v-text-field
                    label="Row"
                    v-model="solver.row"
                />
            </v-col>

            <v-col cols="2" class="mr-2">
                <v-text-field
                    label="Row name"
                    v-model="solver.rowProps.account_group_description"
                />
            </v-col>

            <v-col cols="2" class="mr-2">
                <v-text-field
                    label="Target row"
                    v-model="solver.targetRow"
                />
            </v-col>

            <v-col cols="1" class="mr-2">
                <v-text-field
                    label="Target value"
                    v-model="solver.targetValue"
                />
            </v-col>

            <v-col cols="2" class="mr-2">
                <v-text-field
                    label="Change row"
                    v-model="solver.changeRow"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ZnapSolverDialog',

    props: {
        solver: { type: Object, required: true }
    }
}
</script>

<style scoped>
</style>
